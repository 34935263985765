import router from "@/router";
import { logout } from "./../api/logout";
import { Commit } from "vuex";

const initialState: State = { token: null };

interface State {
  token: string | null;
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAuthorisationToken(state: string, token: string): void {
      Object.assign(state, { token });
    },
  },
  actions: {
    async logout({ commit }: { commit: Commit }): Promise<void> {
      await logout();
      commit("setAuthorisationToken", "");
    },
  },
  getters: {
    isLoggedIn: (state: State) => !!state.token,
    getAuthorisationToken: (state: State) => state.token,
  },
};
