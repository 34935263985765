import RedirectToLoginIfUnauthorisedGuard from "@/router/guards/RedirectToLoginIfUnauthorisedGuard";
import { RouteConfig } from "vue-router";
import routeBuilder from "@/router/RouteBuilder";

const authorisedRoutes: RouteConfig = {
  path: "",
  beforeEnter: RedirectToLoginIfUnauthorisedGuard,
  component: () => import("@/templates/Authorised/Authorised.vue"),
  children: [
    ...routeBuilder("authorised"),
    {
      path: "*",
      name: "notFound",
      component: () => import("@/modules/notFound/views/NotFound.vue"),
    },
  ],
};

export default authorisedRoutes;
