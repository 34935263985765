import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import authorisation from "@/router/routes/authorisation";
import authorised from "@/router/routes/authorised";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [authorisation, authorised];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
