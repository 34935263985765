import Vue from "vue";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CodeMirror = require("vue-codemirror");

import "codemirror/lib/codemirror.css";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

// merge css
import "codemirror/addon/merge/merge.css";

// merge js
import "codemirror/addon/merge/merge.js";

Vue.use(CodeMirror);
