import { RouteConfig } from "vue-router/types/router";

export default function routeBuilder(routeConfig: string): RouteConfig[] {
  const routes: RouteConfig[] = [];
  const context = require.context(`@/modules`, true, /routes\.ts$/);
  context.keys().forEach((component) => {
    const routeConfiguration = context(component);
    if (routeConfiguration.default[routeConfig]) {
      routes.push(...routeConfiguration.default[routeConfig]);
    }
  });

  return routes;
}
