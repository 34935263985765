import { RouteConfig } from "vue-router/types/router";

export default <Record<string, RouteConfig[]>>{
  authorised: [
    {
      path: "home",
      name: "dashboard",
      redirect: { name: "conversion.index" },
    },
    {
      path: "/invoer",
      name: "conversion.create",
      component: () => import("./views/ConversionForm.vue"),
    },
    {
      path: "/logs",
      name: "conversion.index",
      component: () => import("./views/ConversionTable.vue"),
    },
    {
      path: "/logs/:conversionId",
      name: "conversion.show",
      component: () => import("./views/ConversionShow.vue"),
    },
  ],
};
