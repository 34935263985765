import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en",
  },
  theme: {
    themes: {
      light: {
        primary: "#585857",
        accent: "#4b9fa9",
        error: "#E65100",
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      complete: "far fa-check",
      cancel: "far fa-times",
      close: "far fa-times",
      delete: "far fa-trash-alt",
      clear: "far fa-times",
      success: "far fa-check-circle",
      info: "far fa-info-circle",
      warning: "far fa-exclamation",
      error: "far fa-exclamation-triangle",
      prev: "far fa-chevron-left",
      next: "far fa-chevron-right",
      checkboxOn: "fas fa-check-square",
      checkboxOff: "far fa-square",
      checkboxIndeterminate: "fas fa-minus-square",
      delimiter: "far fa-circle",
      sort: "far fa-sort-up",
      expand: "far fa-chevron-down",
      menu: "far fa-bars",
      subgroup: "far fa-caret-down",
      dropdown: "far fa-caret-down",
      radioOn: "far fa-dot-circle",
      radioOff: "far fa-circle",
      edit: "far fa-pencil-alt",
      ratingEmpty: "far fa-star",
      ratingFull: "far fa-star",
      ratingHalf: "far fa-star-half",
      loading: "far fa-sync",
      first: "far fa-step-backward",
      last: "far fa-step-forward",
      unfold: "far fa-arrows-alt-v",
      file: "far fa-paperclip",
      plus: "far fa-plus",
      minus: "far fa-minus",

      // App specific icons
      log: "far fa-file-lines",
      addLog: "far fa-file-plus",
      search: "far fa-search",
      view: "far fa-eye",
      filter: "far fa-filter",
    },
  },
});
