import Vue from "vue";
import Vuex from "vuex";
import authorisation from "@/modules/authorisation/store/authorisation";
import error from "@/application/store/error";
import createPersistedState from "vuex-persistedstate";
import crudTable from "@/modules/crudTable/store/crudTableStore";

Vue.use(Vuex);
const persistedState = createPersistedState({
  paths: ["authorisation", "crudTable"],
});

export default new Vuex.Store({
  plugins: [persistedState],
  modules: {
    authorisation,
    error,
    crudTable,
  },
});
