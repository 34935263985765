import { RouteConfig } from "vue-router";
import Authorisation from "@/templates/Authorisation/Authorisation.vue";
import RedirectToDashboardIfLoggedInGuard from "@/router/guards/RedirectToDashboardIfLoggedInGuard";
import routeBuilder from "@/router/RouteBuilder";

const authorisationRoutes: RouteConfig = {
  path: "",
  beforeEnter: RedirectToDashboardIfLoggedInGuard,
  redirect: { name: "login" },
  component: Authorisation,
  children: [...routeBuilder("authorisation")],
};

export default authorisationRoutes;
