import { RouteConfig } from "vue-router/types/router";

export default <Record<string, RouteConfig[]>>{
  authorisation: [
    {
      path: "login",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "auth/callback",
      name: "auth.callback",
      component: () => import("./views/AuthorisationCallback.vue"),
    },
  ],
};
