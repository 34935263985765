import store from "@/application/store/index";
import { NavigationGuard } from "vue-router/types/router";

const RedirectToDashboardIfLoggedInGuard: NavigationGuard = (
  to,
  from,
  next
) => {
  if (store.getters["authorisation/isLoggedIn"]) {
    next({
      name: "dashboard",
    });
    return;
  }
  next();
};
export default RedirectToDashboardIfLoggedInGuard;
