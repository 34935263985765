import Vue from "vue";
import VueI18n from "vue-i18n";
import nl from "../../locales/nl-NL";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "nl-NL",
  messages: {
    "nl-NL": nl,
  },
  fallbackLocale: "nl-NL",
  numberFormats: {
    "nl-NL": {
      currency: {
        style: "currency",
        currency: "EUR",
      },
      percentage: {
        style: "percent",
        maximumSignificantDigits: 1,
      },
    },
  },
});
