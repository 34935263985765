import { RouteConfig } from "vue-router/types/router";

export default <Record<string, RouteConfig[]>>{
  authorised: [
    {
      path: "/connector/:connectorId/mapper/:mapperId",
      name: "connectorMapper.update",
      component: () => import("./views/ConnectorMapperForm.vue"),
    },
  ],
};
